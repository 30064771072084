import React from 'react'

import Layout from '../components/Layout'
import Section from '../components/Section'
import Terms from '../components/Terms'
import Footer from '../components/Footer'

const TermsPage = () => {
  if (typeof window !== 'undefined') {
    // Make scroll behavior of internal links smooth
    require('smooth-scroll')('a[href*="#"]')
  }

  return (
    <Layout>
      <Section>
        <Terms />
      </Section>
      <Section>
        <Footer />
      </Section>
    </Layout>
  )
}

export default TermsPage
